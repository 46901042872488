<template>
  <div data-cy="advices-page" class="advices-page">
    <div class="main">
      <main>
        <h1 class="center">{{ $t("page.advices.main.title") }}</h1>
        <div class="content-block advice-container flex-b advice1">
          <div class="advice_img advice_img1"><img src="@/assets/img/img1.jpg" alt="" /></div>
          <div class="advice_text">
            <h2>{{ $t("page.advices.content.blockFirst.title") }}</h2>
            <ul class="list">
              <li>{{ $t("page.advices.content.blockFirst.list.itemFirst") }}</li>
              <li>{{ $t("page.advices.content.blockFirst.list.itemSecond") }}</li>
              <li>{{ $t("page.advices.content.blockFirst.list.itemThird") }}</li>
            </ul>
          </div>
        </div>

        <div class="content-block advice-container flex-b advice2">
          <div class="advice_img advice_img2">
            <img class="desk" src="@/assets/img/img2.jpg" alt="" />
            <img class="tablet" src="@/assets/img/img2_tab.jpg" alt="" />
          </div>
          <div class="advice_text">
            <h2>{{ $t("page.advices.content.blockFirst.title") }}</h2>
            <ul class="list">
              <li>{{ $t("page.advices.content.blockSecond.list.itemFirst") }}</li>
              <li>{{ $t("page.advices.content.blockSecond.list.itemSecond") }}</li>
              <li>{{ $t("page.advices.content.blockSecond.list.itemThird") }}</li>
            </ul>
          </div>
        </div>

        <div class="content-block advice_cont">
          <h2 class="center">{{ $t("page.advices.content.blockThird.title") }}</h2>

          <div class="flex-s advice_instr">
            <div class="advice-info">
              <div class="insturct_step-head flex-s">
                <span>1</span>
                <p>{{ $t("page.advices.content.blockThird.stepFirst") }}</p>
              </div>

              <div class="advice-body">
                <div class="field">
                  <input
                    ref="link"
                    class="input"
                    type="text"
                    name="org"
                    placeholder=" "
                    :value="referralLink"
                    readonly
                  />
                  <span class="placeholder">{{ $t("label.name") }}</span>

                  <span data-event="copied_link" class="copied_link" @click="onClickCopy">
                    <img src="@/assets/img/icon/copy.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            <span class="advice_sep">{{ $t("label.or") }}</span>

            <div>
              <div class="insturct_step-head flex-s">
                <span>2</span>
                <p>{{ $t("page.advices.content.blockThird.stepSecond") }}</p>
              </div>

              <div class="advice-body">
                <div class="but" @click="onClickAddReferral">
                  {{ $t("page.instruction.stepFirst.actionTab") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import "@/assets/lib/jquery.maskedinput";
import "@/assets/js/common/common";
import "@/assets/css/main.css";

import notify from "@/services/notify.service";

const CONTENT_WIDTH = 1126;

export default {
  name: "Advices",

  computed: {
    ...mapGetters(["userHash"]),

    referralLink() {
      return `${window.location.origin}/landing?agent=${this.userHash}`;
    },
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    onClickCopy() {
      try {
        this.$refs.link.select();

        document.execCommand("copy");
        notify.success("copiedSuccessfully");
      } catch (error) {
        notify.error("copyError");
      }
    },

    onClickAddReferral() {
      this.$router.push({ name: "AgentReferralAdd" });
    },
  },
};
</script>

<style src="@/assets/css/adaptiv.css" scoped />
